.blog {
    border: none;
  }
  
  .table-responsive .form-control::placeholder {
    color: #fff;
  
  }
  
  .table thead th {
    border: none;
  }
  
  
  .table td,
  .table th .form-control {
    border-radius: 0px;
  }
  
  
  .for-img-paste {
    border: 1px dotted darkgray;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .p-name .form-control::placeholder {
    color: #8d8d8d;
  }
  /* buttons Onhover */
  .delete-button:hover {
    background-color: #f8f9fa; 
  }
  .name-input-top{
    min-width: 57%;
  }
  .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  /* .input-group-append{
    position: relative;
  }
  .input-group-text{
    position: absolute;
    top: 0;
    right: 0px;
  } */
  .input-txt {
    max-width: 49.5%;
  }
  .input-span-txt{
    padding: 7px 16px;
  }
  /* ==== ======  image Upload Css ====== ====*/
  .image-upload-container {
    padding: 2rem;
    display: flex;
    align-items: center;
  }
  
  .box-decoration {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-upload-label {
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .image-upload-input {
    display: none;
  }
  
  .custom-xx-large {
    font-size: 32px;
    font-weight: 500;
    color: solid black;
  }
  
  .image-upload-button:hover {
    background-color: #00e608;
  }
  
  .img-display-before {
    font-size: 18px;
    width: 150px;
  }
  
  .img-display-after {
    height: 150px;
    width: 150px;
  }
  
  
  
   