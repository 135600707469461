 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   text-decoration: none !important;
 }



 .Header-Links ul {
   width: 100%;
   display: flex;
   flex-direction: row;
   font-size: 14px;
   gap: 20px;
   margin-top: 10px;
   padding: 10px;
   background-color: #009CA6;
   border-radius: 5px;
 }

 .Header-Links ul li {
   list-style: none;

 }

 .Header-Links ul li a {
   color: #fff;
   font-weight: 600;
   font-size: inherit;
   position: relative;

 }



 body::-webkit-scrollbar {
   background-color: transparent;
 }

 input[type="number"]::-webkit-inner-spin-button,
 input[type="number"]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 body::-webkit-scrollbar-thumb {
   background: linear-gradient(#009CA6, #F77E53);
   border-radius: 20px;
 }

 .budget,
 .main-heading {
   background-color: #f2f3f7;
 }

 .budget .services {
   width: 100%;
   height: auto;
 }

 .budget .one {
   width: 100%;
   height: auto;
   background-color: #ffffff80;

   border-radius: 10px;
   box-shadow: 1px 2px 5px rgba(43, 42, 42, 0.27), inset -20px -20px 60px rgba(255, 255, 255, 0.27);

 }

 .budget .drop {
   font-weight: bolder;
   border: none;
   text-align: center;
 }

 /* slider css */


 .budget h2,
 h4 {
   /* position: absolute; */
   top: 10px;
   color: #009CA6;
   font-size: x-large;


 }

 .budget .sec-one {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .budget .input_num {
   color: #F77E53;
   font-weight: bold;
   width: 125px;
 }

 /* slider css */
 .budget .para {
   font-weight: bold;
   margin-top: 5px;
 }

 .budget .second-side {
   width: 100%;
   height: auto;
   background-color: #ffffff80;
   backdrop-filter: blur(6px);
   border-radius: 10px;
   box-shadow: 1px 2px 5px rgba(43, 42, 42, 0.225), inset -20px -20px 60px rgba(255, 255, 255, 0.225);
   padding: 20px 30px;
 }

 .budget .second-side .total {
   border-bottom: 1px dotted gray;

   border-top: 1px dotted gray;
   margin-top: 22%;
   padding-top: 10px;

 }

 .budget .second-side .inr-total {
   justify-content: space-between;
 }

 .budget .second-side .inner .head {
   margin: 15px;

 }

 .budget .btn {
   width: 100%;
   background-color: #009CA6;
   color: white;
   border-radius: 15px;
   padding: 1%;

 }

 .budget .btnn {
   margin-bottom: 3%;
   margin-top: 3%;
   background-color: #F77E53;
 }

 /* .budget .dropd {
     margin-top: 45px !important;
     margin-right: 44px;
     display: flex;

 } */
 .budget .drop {
   display: flex;
   align-items: center;
 }

 .budget .img1 img {

   max-width: 100px;
   animation: beat 1s infinite alternate;
 }

 @keyframes beat {
   from {
     transform: scale(1);
   }

   to {
     transform: scale(1.1);
   }
 }

 .budget .one .dropd .yes,
 .no {
   font-size: large;
   font-weight: bold;
   color: #009CA6;
 }

 .budget .one .dropd .no {

   color: #F77E53;
 }

 .modal-header .close {
   padding: 0;
   margin: 0;
   width: 30px;
   height: 35px;


 }

 .budget .services .enter_modal {
   color: #F77E53;
 }

 .main-heading h1 {
   color: #F77E53;
   position: relative;
   padding: 10px 0;

 }


 .main-heading h1::after {
   content: "";
   position: absolute;
   height: 3px;
   width: 50%;
   left: 25%;
   bottom: 0%;
   background-color: #009CA6;
 }

 .main-heading h1::before {
   content: "";
   position: absolute;
   height: 3px;
   width: 50%;
   left: 25%;
   top: 0%;
   background-color: #009CA6;
 }

 .budget .services .hh6 {
   color: #009CA6;
   font-weight: bold;
   font-size: large;
 }

 .budget .services .hh66 {
   color: #F77E53;
   font-weight: bold;
   font-size: large;
   /* margin-left: 5px; */
 }

 .fa-indian-rupee-sign {
   margin-right: 2px;
 }

 .budget .second-side .grand {
   border-top: 1px dotted gray;
   padding-top: 7px;
 }

 .budget .services .one .modal .cardd img {
   height: 70px;
   width: 70px;
   align-items: center;

 }

 .budget .services .one .modal .cardd {
   border-bottom: 1px dotted gray;
   justify-content: space-between;
 }

 .budget .services .one .logo {
   color: #F77E53;
   font-weight: bold;
 }

 .budget .services .one .doc {
   color: #F77E53;
   font-weight: bold;
 }

 @media (max-width:992px) {
   .main-heading h1 {
     font-size: x-large;
     padding: 10px 0;
   }

 }

 @media (max-width:767px) {
   .range-container {
     width: 100%;
   }

   h2 {
     text-align: center;
   }

   .special {
     margin-left: 20px;


   }

   .budget .para {
     font-size: small;
   }

   .budget .drop {
     margin-left: 22px;
   }

   .budget .dropd {
     top: -51 !important;
   }

   input[type='range'] {

     margin: 82px 0px;


   }

   .main-heading h1 {
     font-size: small;
     padding: 10px 0;
   }

 }

 /* text editor */
 .editor button i {
   display: flex;
   justify-content: center;

 }

 .description h1 {
   color: #009CA6;
   font-size: xx-large;
 }

 .description {
   margin-bottom: 50px;
   padding: 10px;

   border-radius: 5px;
 }

 .description h1 {
   margin-bottom: 0;
 }

 .description p {
   font-size: 14px;
 }

 #document {
   margin: 25px 0;
   padding: 25px;
   font-size: 15px;
   line-height: 1.4;
   border-radius: 5px;
   border: 2px solid transparent;
   outline: none;
   height: 250px;
 }

 #document:hover,
 #document:focus {
   border-color: #eee;
 }

 #document li {
   margin-bottom: 10px;
 }

 #document p img {
   float: left;
   max-width: 250px;
   width: 100%;
   height: auto;
   margin: 5px 5px 5px 0;
 }

 #document p img.right {
   float: right;
   margin: 5px 0 5px 5px;
 }

 font[size='1'] {
   font-size: 10px;
 }

 font[size='2'] {
   font-size: 12px;
 }

 font[size='3'] {
   font-size: 14px;
 }

 font[size='4'] {
   font-size: 16px;
 }

 font[size='5'] {
   font-size: 18px;
 }

 font[size='6'] {
   font-size: 21px;
 }

 font[size='7'] {
   font-size: 26px;
 }

 .editor {
   border: 1px solid #999;
   border-radius: 5px;
   padding: 10px;
   background: #eee;
 }

 .editor .hide {
   display: none;
 }

 .editor .divider {
   margin: 0 4px;
   display: inline-block;
 }

 .editor .divider:after {
   content: "|";
   color: #666;
 }

 .editor button {
   width: 25px;
   text-transform: uppercase;
   font-weight: 700;
 }

 .editor button[data-action='italic'] {
   font-style: italic;
 }

 .editor button[data-action='underline'] {
   text-decoration: underline;
 }

 .editor button:hover {
   background: #ccc;
   color: #fff;
 }

 .editor button,
 .editor select {
   background: #fff;
   border: 1px solid #666;
   border-radius: 5px;
   height: 25px;
   margin: 5px 2px;
 }

 .editor button:hover,
 .editor select:hover {
   cursor: pointer;
 }

 .context-menu {
   background: #fff;
   position: absolute;
   top: 0;
   left: 50%;
   padding: 15px;
   border: 1px solid #333;
 }

 #document {
   height: 250px;
 }

 /* text editor */

 /* ................................upload........................ */
 /* @import url('https://fonts.googleapis.com/css?family=Poppins');

html, button, input, select, textarea {
    color: #333;
}


body {
  margin: 2rem;
  padding: 0;
  font-family: 'Poppins';
  background: #f7f7f7;
}

button, input {
    line-height: normal;
    text-transform: none;
}

svg {
    vertical-align: middle;
} */

 .blue--btn {
   background: #009CA6;
   border: 1px solid #009CA6;
   color: #fff;
   border-radius: 4px;
   padding: 4px 12px;
   cursor: pointer;
   margin-top: 15px;
 }

 .form__field {
   margin: 24px 0px;
   position: relative;
 }

 .form__title {
   font-size: 1.125rem;
   font-weight: 700;
 }

 .form__load-img li {
   margin-bottom: 4px;
 }

 .form__load-img ul {
   list-style: none;
 }

 .load__img-wrap {
   background-color: white;
   margin-top: 8px;
   width: 100%;
   position: relative;
 }

 .load__img-content {
   display: flex;
   justify-content: start;
   align-items: center;
   /* padding: 28px; */
   /* border: 1px dashed #c5c5c5;
    border-radius: 8px; */
   background-color: #f9f9fb;
 }

 .content__wrapper {
   /* min-width: 310px; */
   /* display: flex; */
   justify-content: space-evenly;
   align-items: center;
 }

 .upload__TipIcon {
   position: relative;
 }

 .upload__Tooltip {
   display: none;
   position: absolute;
   top: 27px;
   right: -152px;
   padding: 16px;
   color: #767676;
   font-size: 12px;
   font-weight: 400;
   line-height: 15px;
   text-align: left;
   white-space: normal;
   z-index: 999999;
   background: #fff;
   box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
   border-radius: 8px;
   width: 275px;
 }

 .upload__TipIcon:hover .upload__Tooltip {
   display: block;
 }

 .margin--top-16 {
   margin-top: 16px;
 }

 .error__msg {
   visibility: visible;
   color: #ed0101 !important;
   font-size: 13px;
   font-weight: 550;
   margin: 15px 0;
   line-height: 15px;
 }

 .hidden {
   display: none !important;
   visibility: hidden;
 }

 .upload__thumbnails {
   margin: 24px 0 32px;
 }

 .imgs__wrapper,
 .item__imgs {
   display: flex;
 }

 .item__wrapper:nth-of-type(1) {
   margin-left: 0;
 }

 .item__wrapper {
   position: relative;
   margin-left: 1.5rem;
 }

 .remove__img {
   position: absolute;
   z-index: 999;
   left: 64px;
   top: -7px;
 }

 .remove__icon {
   background: #fff;
   box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
   border-radius: 50% 50%;
   cursor: pointer;
   width: 24px;
   height: 24px;
   border: none;
 }

 .content__wrapper.disable {
   color: #BABABA;
 }

 .content__wrapper.disable path {
   fill: #BABABA;
 }

 .content__wrapper.disable button {
   background: #BABABA;
   border: 1px solid #BABABA;
   cursor: inherit;
 }

 input[type=file]::file-selector-button {
   border: 2px solid #009CA6;
   padding: .2em .6em;
   border-radius: .2em;
   background-color: #009CA6;
   transition: 1s;
   color: white;
 }

 .image-row {
   display: flex;
   flex-wrap: nowrap;
   /* Prevent wrapping to the next line */
 }

 .image-preview-container {
   margin-right: 10px;
   /* Adjust the spacing between images */
 }

 .image-preview {
   width: 100px;
   height: 100px;
 }

 .cut-icon {
   cursor: pointer;
 }